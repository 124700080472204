import axios from 'axios';

// axios.defaults.withCredentials = true;

const token = JSON.parse((localStorage.getItem('user') as any));

axios.defaults.headers.common['Authorization'] = token?.token ? `Bearer ${token?.token}` : '';

export default axios.create({
	baseURL : '',
	headers: { "Content-Type": "multipart/form-data" },
});