import React, { FC, Fragment , createContext, useState, useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth  } from './contains/AuthContext';

export interface RequireAuthProps {
  allowedRoles?: any;
}

const RequireAuth: FC<RequireAuthProps> = ({ allowedRoles }) => {
    const { user } = useAuth();
    const location = useLocation();
    return (
        (
        	(user?.token && user?.role) ? (((allowedRoles?.includes(user?.role) == true))
                                    ? <Outlet />
                                    : <Navigate to="/unauthorized" state={{ from: location }} replace />)
                        : <Navigate to="/login" state={{ from: location }} replace />

            /*((user?.token && (allowedRoles?.includes(user?.token) == true))
                        ? <Outlet />
                        : <Navigate to="/login" state={{ from: location }} replace />)*/
        )
    );
}

export default RequireAuth;