import React, { useEffect, useState } from 'react';

// Css File
import '../../styles/flashmessage.css';

// Images File
import success from '../../images/icons/success.png';
// import error from '../../images/icons/error.png';



function FlashMessage() {
    const [isPopupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        let popupTimer: NodeJS.Timeout | undefined;

        if (isPopupVisible) {
            // Start a timer to hide the popup after 5 seconds
            popupTimer = setTimeout(() => {
                setPopupVisible(false);
            }, 5000);
        }

        return () => {
            // Clear the timer when the component unmounts or when isPopupVisible changes
            if (popupTimer) {
                clearTimeout(popupTimer);
            }
        };
    }, [isPopupVisible]);

    const handlefmp = () => {
        setPopupVisible(true);
    };

    const handlefmpclose = () => {
        setPopupVisible(false);
    };

    return (
        <>
            <div className="cmp-btn" onClick={handlefmp}></div>

            <div className={`flash-message sf-msg ${isPopupVisible ? 'fmp-message' : ''}`}>
                <div className="flash-message-inner">
                    <span className="close-icon" onClick={handlefmpclose}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                        </svg>
                    </span>
                    <img className="flash-img" src={success} alt="cdf" />
                    <div className="flash-text">
                        <h6>Success</h6>
                        <p id="sf-msg-success">Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic ipsa nostrum aperiam.</p>
                    </div>
                </div>
            </div>

            {/* <div className={`flash-message ef-msg ${isPopupVisible ? 'fmp-message' : ''}`}>
                <div className="flash-message-inner">
                    <span className="msg-close">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                        </svg>
                    </span>
                    <img className="flash-img" src={error} alt="cdf" />
                    <div className="flash-text">
                        <h6>Error</h6>
                        <p id="ef-msg-error">Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic ipsa nostrum aperiam.</p>
                    </div>
                </div>
            </div> */}

            <div className={` flash-message-popup ${isPopupVisible ? 'fmp-active' : ''} `} onClick={handlefmpclose}></div>
        </>
    );
}

export default FlashMessage;
