import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ProductCard from "components/ProductCard";
import { PRODUCTS, Product } from "data/data";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import axios from '../../contains/axios';
import API_HOST from '../../config/config';
import { useAuth  } from '../../contains/AuthContext';

interface WishlistItem{
  id:number;
  favourite:boolean;
  product:Product;
}
const AccountSavelists = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState<WishlistItem[]>([]);
  const [wishlistWithProduct, setWishlistWithProduct] = useState<WishlistItem[]>([]);
  const [wishlistWithProductData, setWishlistWithProductData] = useState<WishlistItem[]>([]);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (user) {
      if (user?.token && user?.role && user?.role=='user') {
      }
      else
      {
        navigate('/login');
      }
    }
    else
    {
      navigate('/login');
    }
  }, [user, navigate]);

  async function fetchWishlistData() {
    await axios.post(API_HOST+'/api/get-wishlist',{
      user_id : `${user?.id}`,
      page:page,
      per_page:30
    })
    .then(response => {
      if(response.data.success == 0)
      {
        setWishlist(response.data.data.data);
        setPage(page+1);
      }
    })
    .catch(error => {
      setWishlist([]);
      console.error('Error fetching data:', error);
    });        
  }

  useEffect(() => {
    fetchWishlistData();
  }, []);

  useEffect(() => {
    const newData = wishlist.map((item: WishlistItem) => ({
      ...item,
      product: {
        ...item.product,
        favourite: true, // Set favourite to 1
      },
    }));
    setWishlistWithProduct(newData);
  }, [wishlist]);

  const renderSection1 = () => {
    return (
      <div className="space-y-10 sm:space-y-12">
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">
            List of saved products
          </h2>
        </div>

        <div className="grid grid-cols-2 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-6">
        { wishlistWithProduct && wishlistWithProduct.map((stay:WishlistItem) => (
            <ProductCard key={stay.id} data={stay.product} />
          ))}
        </div>
        { wishlistWithProduct.length == 0 && page == 1 &&
            <div className="skeletant-target">
              <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-2 sm:gap-x-8 sm:gap-y-10 mt-8 lg:mt-10">

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

                <div className="">
                  <div className="skeletant-design h-[300px]"></div>
                  <div className="skeletant-design ld-no-img h-[24px] mt-4"></div>
                  <div className="skeletant-design ld-no-img h-[12px] mt-2"></div>
                  <div className="flex">
                    <div className="skeletant-design ld-no-img h-[30px] w-[100px] mt-2 mr-1"></div>
                    <div className="skeletant-design ld-no-img h-[30px] w-[50px] mt-2"></div>
                  </div>
                </div>

              </div>
            </div>
            }
        {/* <div className="flex !mt-20 justify-center items-center">
          <ButtonSecondary loading>Show me more</ButtonSecondary>
        </div> */}
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
