import React from "react";
import ReactDOM from "react-dom/client";
//
//import { AuthProvider } from './contains/AuthProvider';
import { AuthProvider } from './contains/AuthContext';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import "./styles/index.scss";
import "./index.css";
import "./custom.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";
import { CartProvider } from './context/CartContext';
//import { ReactPixel } from 'react-facebook-pixel';
//
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const options = {
  autoConfig: true, // Set pixel's autoConfig
  debug: false, // Enable logs
};

//ReactPixel.init('YOUR_PIXEL_ID', options);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <PayPalScriptProvider options={{ 'clientId': "AfVK2pGGFzUUQSZivvmef9y-G0pc7KGhlrjCyP7twW1EaTIoNjT5RvozlID0tg0dWVPO4ouVPTqCReVQ",'currency': 'USD' }}>
    <AuthProvider>
      <CartProvider>
        <App />
      </CartProvider>
    </AuthProvider>
  </PayPalScriptProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
